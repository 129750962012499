import { inject, reactive } from 'vue';
import { store } from "../store"
import router from '../router'
import Segments from "./Segments";
const { TrackCheckout, TrackCheckoutCompleted, CoinsEarn, CoinsRedeemed } = Segments();

export default function Checkout() {
    const axios = inject('axios');

    const checkoutData = reactive({
        loading: false,
        data: [],
        items: [],
        appliedCoins: null,
        error: '',
        btnEnabled: true,
    });

    const shippingData = reactive({
        loading: false,
        data: [],
        error: ''
    });

    const coins = reactive({
        loading: false,
        data: [],
        error: ''
    });

    const cards = reactive({
        loading: false,
        data: [],
        error: ''
    });

    const formUrl = reactive({
        url: ''
    });
    const error = reactive({
        loaded: false,
        msg: ''
    });

    const getCheckoutData = (flag = 0,analytics=0) => {
        checkoutData.loading = true;
        axios.authApi.get('/checkout').then(resp => {
            if (resp.status) {
                checkoutData.data = resp.data.data;
                // if (resp.data.data.shipping_id && flag == 0) {
                //     getShippingMethods(checkoutData.data.shipping_id);
                // }
                if(analytics){
                    TrackCheckout(checkoutData.data);
                }
            } else {
                checkoutData.error = resp.error;
            }
            checkoutData.loading = false;
        }).catch(error => {
            console.log(error);
        })
    }

    const getCheckoutItems = () => {
        checkoutData.loading = true;
        axios.authApi.get('/checkout-items').then(resp => {
            if (resp.status) {
                checkoutData.items = resp.data.data;
            } else {
                checkoutData.error = resp.error;
            }
            checkoutData.loading = false;
        }).catch(error => {
            console.log(error);
        })
    }

    const getCheckout = (analytics=0) => {
        if(analytics){
            shippingData.loading = true;
        }
        axios.authApi.get('/checkout-data').then(resp => {
            if (resp.status) {
                let temp = checkoutData.data;
                let data = resp.data.data;
                if(temp.appliedStoreCredit){
                    data.appliedStoreCredit = {
                        label: "Store Credit",
                        value: temp.appliedStoreCredit.value,
                    };
                    data.canApplyStoreCredit = false;
                }
                checkoutData.data = data;
                if(analytics){
                    getShippingMethods();
                    TrackCheckout(checkoutData.data, checkoutData.items);
                }
            } else {
                checkoutData.error = resp.error;
            }
            // checkoutData.loading = false;
        }).catch(error => {
            console.log(error);
        })
    }

    const getShippingMethods = () => {
        shippingData.data = [];
        shippingData.loading = true;
        axios.authApi.get('/get-shipping-methods').then(resp => {
            if (resp.status) {
                shippingData.data = resp.data.data;
            } else {
                shippingData.error = resp.error;
            }
            shippingData.loading = false;
        }).catch(error => {
            shippingData.loading = false;
            console.log(error);
        })
    }

    const getCoins = () => {
        axios.authApi.get('/points').then(resp => {
            if (resp.status) {
                coins.data = resp.data.data;
            } else {
                coins.error = resp.error;
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const couponApply = async (code) => {
        await axios.authApi.post('/apply-coupon', {
            code: code
        }).then(resp => {
            if (resp.data.status) {
                checkoutData.data.coupons = resp.data.data;
                checkoutData.data.gTotal = checkoutData.data.gTotal - resp.data.data[0]?.cartDiscount;
                getCheckout();
            } else {
                store.dispatch('setToast', { status: true, msg: resp.data.error, type: 'danger' })
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const creditApply = (v) => {
        // axios.authApi.get('/applyStoreCredit').then(resp => {
        //     if (resp.status) {
        //         getCheckoutData(1);
        //     }
        // }).catch(error => {
        //     console.log(error);
        // })
    }

    const couponRemove = (id) => {
        let dis = checkoutData.data.coupons[0]?.cartDiscount;
        axios.authApi.get('/remove-coupon/' + id).then(resp => {
            if (resp.data.status) {
                checkoutData.data.gTotal = checkoutData.data.gTotal + dis;
                checkoutData.data.coupons = [];
                getCheckout();
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const applyCoins = (coin) => {
        axios.authApi.post('/applyPoints', {
            amount: coin.value
        }).then(resp => {
            if (resp.status) {
                // getCheckoutData(1);
                checkoutData.appliedCoins = coin;
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const pointRemove = () => {
        axios.authApi.get('/clear-points').then(resp => {
            if (resp.status) {
                // getCheckoutData(1);
                checkoutData.appliedCoins = null;
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const nmiStepOne = (data, flag = 0) => {
        checkoutData.btnEnabled = false;
        if (checkoutData.appliedCoins) {
            data['appliedPoints'] = checkoutData.appliedCoins.value;
        }
        axios.authApi.post('/select-shipping-method', data).then(resp => {
            if (resp.status) {
                if (flag == 0) {
                    // getCreditCards();
                    getCheckout();
                }
                // formUrl.url = resp.data.data["form-url"];
                checkoutData.btnEnabled = true;
            }
        }).catch(error => {
            console.log(error);
        })
    }
    
    const nmiStepTwo = (data,ifError,stockError) => {
        let params = {
            // user_saved: data.creditCard,
            ...data,
            formUrl: formUrl.url,
        }
        if (checkoutData.appliedCoins) {
            params['appliedPoints'] = checkoutData.appliedCoins.value;
        }
        axios.authApi.post('/payment', params).then(resp => {
            if(resp.data.status == 'stock_error'){
                stockError(resp.data);
            } else if(resp.data.status == true){
                checkoutData.data.reward = {};
                checkoutData.data.payment_method = 'Credit Card';
                if(checkoutData.data.coins_earned) CoinsEarn(checkoutData.data.coins_earned);
                if(checkoutData.appliedCoins) {
                    checkoutData.data.reward.name = '$'+ checkoutData.appliedCoins.discount +' Off An Order - '+ checkoutData.appliedCoins.points +' Medusa Coins';
                    checkoutData.data.reward.points_redeemed = checkoutData.appliedCoins.points;
                    checkoutData.data.reward.discount = checkoutData.appliedCoins.discount;
                    CoinsRedeemed(checkoutData.appliedCoins.points);
                }

                // TrackCheckoutCompleted(checkoutData.data);

                router.push("/thankyou/" + resp.data.order_id);
            } else {
                let error = resp.data.error;                
                if(resp.data?.card){
                    error = resp.data;
                }
                ifError(error);

                // Log Error
                axios.authApi.post('/logError', resp).then(r => console.log(r)).catch(e => console.log(e));
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const getCreditCards = () => {
        cards.data = [];
        cards.loading = true;
        axios.authApi.get('/credit-cards').then(resp => {
            if (resp.status) {
                cards.data = resp.data.data;
            } else {
                cards.error = resp.error
            }
            cards.loading = false;
        }).catch(error => {
            cards.loading = false;
            console.log(error);
        })
    }

    return {
        checkoutData,
        getCheckoutData,
        shippingData,
        getShippingMethods,
        coins,
        getCoins,
        applyCoins,
        couponRemove,
        pointRemove,
        creditApply,
        nmiStepOne,
        couponApply,
        getCreditCards,
        cards,
        nmiStepTwo,
        error,
        getCheckoutItems,
        getCheckout
    }
}